<template>
  <div id="request">
    <v-btn
      dark
      block
      class="mb-5"
      :color="page.color"
      @click="openPopup('https://www.jamie-system.com/event/11520')"
    >
      Request Medical Information
    </v-btn>
    <v-divider class="my-5"></v-divider>
    <p class="preamble" v-if="!submitted">
      For all general inquiries, please submit the form below. Your message will
      be submitted to a secure, monitored inbox. You can expect to receive a
      response within 48 hours.
    </p>
    <v-form v-if="!submitted">
      <v-text-field
        :color="page.color"
        v-model="requestInfo.name"
        label="Name*"
        prepend-icon="mdi-account"
        outlined
        @change="validateName()"
        @keyup="showNameError = false"
      ></v-text-field>
      <v-alert
        v-if="showNameError"
        dense
        text
        type="error"
        style="margin-top: -25px"
      >
        Please enter your name.
      </v-alert>
      <v-text-field
        :color="page.color"
        v-model="requestInfo.email"
        label="Email*"
        prepend-icon="mdi-email"
        outlined
        @change="validateEmail()"
        @keyup="showEmailError = false"
      ></v-text-field>
      <v-alert
        v-if="showEmailError"
        dense
        text
        type="error"
        style="margin-top: -25px"
      >
        Please enter a valid email address.
      </v-alert>
      <!-- <v-textarea
        :color="page.color"
        v-model="requestInfo.message"
        label="Message*"
        outlined
        @change="validateMessage()"
        @keyup="showMessageError = false"
      ></v-textarea>
      <v-alert
        v-if="showMessageError"
        dense
        text
        type="error"
        style="margin-top: -25px"
      >
        Please include your message.
      </v-alert> -->
      <p class="preamble" style="font-size: 14px">*Required fields</p>
      <v-btn
        depressed
        :color="page.color"
        id="submit-btn"
        @click="submit"
        :disabled="submitDisabled"
      >
        <v-icon class="mr-2">mdi-send</v-icon>
        Submit
      </v-btn>
    </v-form>
    <v-alert v-if="submitted" text type="success">
      Thank you for your submission. Please expect a response in 48 hours.
    </v-alert>
    <v-btn
      depressed
      :color="page.color"
      id="submit-btn"
      @click="close"
      v-if="submitted"
    >
      <v-icon class="mr-2">mdi-close</v-icon>
      Close
    </v-btn>
  </div>
</template>

<script>
import * as EmailValidator from "email-validator";
export default {
  name: "Contact",
  props: ["page", "submitted"],
  data: () => ({
    requestInfo: {
      name: "",
      email: "",
      message: ""
    },
    showEmailError: false,
    showNameError: false,
    showMessageError: false
  }),
  computed: {
    submitDisabled() {
      return this.noEmail || this.noName || this.noMessage || this.invalidEmail;
    },
    noEmail() {
      return this.requestInfo.email.trim() === "";
    },
    noName() {
      return this.requestInfo.name.trim() === "";
    },
    noMessage() {
      return false;
      // return this.requestInfo.message.trim() === "";
    },
    invalidEmail() {
      const EMAIL = this.requestInfo.email.trim();
      const VALID = EmailValidator.validate(EMAIL);
      return !VALID;
    }
  },
  methods: {
    openPopup(url) {
      this.requestPopup = false;
      window.open(url, "_blank");
    },
    validateName() {
      this.showNameError = this.noName;
    },
    validateEmail() {
      this.showEmailError = this.noEmail || this.invalidEmail;
    },
    validateMessage() {
      this.showMessageError = this.noMessage;
    },
    submit() {
      this.$hub.$emit("submitContact", this.requestInfo);
    },
    close() {
      this.$hub.$emit("closeContact");
    }
  }
};
</script>

<style lang="scss">
#request {
  max-width: 800px;
  margin: 30px auto 25px;
  padding: 0 8px;
  font-family: Karbon, Verdana, Geneva, sans-serif;
  textarea {
    line-height: 115%;
  }
}
#submit-btn {
  min-width: 16rem;
  margin-top: 16px;
  height: 42px;
  color: white;
}
.preamble {
  font-size: 20px;
  padding-bottom: 1em;
  margin-top: 0.75em;
  line-height: 115%;
}
.v-menu__content {
  margin-top: -75px;
}
</style>
